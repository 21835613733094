import { FC, Fragment } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PgeButton from '../pge-button/PGEButtons';
import { ColorName, Shadow } from '../../constants';
import { ContentProps, RegExArray, UseHeading } from './types.d';



const useHeading: UseHeading = heading => {
  const span: number = heading.startsWith('--')
    ? Number(heading.slice(2, 3))
    : 1;
  const text: string = heading.replace(/--\d\s*|{{.+}}/g, '').trim();
  const stylesMatch: RegExArray | null = heading.match(/{{(.+)}}/);
  const styles: Record<string, string> = {};

  if (stylesMatch !== null) {
    const [, stylesGroup]: RegExArray = stylesMatch;
    const [, value = 'auto']: RegExArray | Array<RegExArray> =
      stylesGroup.match(/width:(\d+%)/) || [];
    styles['width'] = value;
  }

  return { span, text, styles };
};

const ContentList: FC<ContentProps> = ({
  rows,
  heading,
  introCopy,
  tableHeader,
  button,
  columnBackground = 'None',
  bordered = false,
}) => {
  // Vars
  const matches = useMediaQuery('(min-width:768px)');

  const theme = useTheme()
  const { spacing, typography, breakpoints, palette } = theme;

  return (
    (<Fragment>
      {heading && <div css={{
        fontSize: spacing(4.5),
        color: palette.text.primary,
        paddingBottom: spacing(2),
        lineHeight: typography.pxToRem(40),
        fontWeight: 300,

        [breakpoints.down('sm')]: {
          fontSize: spacing(3),
          paddingTop: spacing(2.5),
          lineHeight: typography.pxToRem(28),
        },
      }}>{heading}</div>}
      {introCopy && <div css={{
        fontSize: spacing(2.5),
        color: palette.text.primary,
        paddingBottom: spacing(2),
        lineHeight: typography.pxToRem(24),

        [breakpoints.down('sm')]: {
          fontSize: spacing(2),
          lineHeight: typography.pxToRem(20),
        },
      }}>{introCopy}</div>}
      {matches ? (
        <TableContainer css={{
          '&.MuiPaper-elevation1': {
            boxShadow: 'none',

            [breakpoints.up('sm')]: {
              boxShadow: Shadow.ONE,
            },
          },

          '& .MuiTableRow-root': {
            border: '1px solid #d7d7d7',

            '&.Grey:nth-of-type(even)': {
              background: ColorName.Grey,
            },

            '&.LightBlue:nth-of-type(even)': {
              background: ColorName.RiverBlue,
            },

            [breakpoints.up('sm')]: {
              border: 0,
            },
          },

          '& .MuiTableBody-root::before': {
            content: '""',
            display: 'block',
            height: spacing(2),

            [breakpoints.up('sm')]: {
              content: 'none',
            },
          },

          '& th, & td': {
            padding: spacing(1.5),
            wordWrap: 'break-word',
            overflowWrap: 'anywhere',

            [breakpoints.up('sm')]: {
              padding: spacing(2),
            },
          },

          '& th': {
            backgroundColor: palette.text.primary,
            color: palette.common.white,
            minWidth: 118,
            [breakpoints.down('md')]: {
              minWidth: 'auto',
            },
          },
          '& tr td:first-child': {
            boxShadow: bordered ? '2px 0px 8px 0px rgba(219,219,219,0.75)' : '',
          },
        }} component={Paper}>
          <Table aria-label="customized table">
            {tableHeader && (
              <TableHead css={{
                borderRadius: spacing(0.625, 0, 0, 0),
                opacity: 1,

                [breakpoints.down('sm')]: {
                  borderRadius: 0,
                },
              }}>
                <TableRow>
                  {tableHeader.map((headerElm, index) => {
                    if (!headerElm) {
                      return null;
                    }

                    const { span, text, styles } = useHeading(headerElm);

                    return (
                      <TableCell
                        key={index}
                        style={styles}
                        colSpan={span}
                        scope="col"
                      >
                        <Typography variant="h3" component="h4">
                          {text}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}
            {rows && (
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index} classes={{ root: columnBackground }}>
                    {row.map((cellData, cellDataIndex) => (
                      <TableCell key={cellDataIndex}>{cellData}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <TableContainer css={{
          '&.MuiPaper-elevation1': {
            boxShadow: 'none',

            [breakpoints.up('sm')]: {
              boxShadow: Shadow.ONE,
            },
          },

          '& .MuiTableRow-root': {
            border: '1px solid #d7d7d7',

            '&.Grey:nth-of-type(even)': {
              background: ColorName.Grey,
            },

            '&.LightBlue:nth-of-type(even)': {
              background: ColorName.RiverBlue,
            },

            [breakpoints.up('sm')]: {
              border: 0,
            },
          },

          '& .MuiTableBody-root::before': {
            content: '""',
            display: 'block',
            height: spacing(2),

            [breakpoints.up('sm')]: {
              content: 'none',
            },
          },

          '& th, & td': {
            padding: spacing(1.5),
            wordWrap: 'break-word',
            overflowWrap: 'anywhere',

            [breakpoints.up('sm')]: {
              padding: spacing(2),
            },
          },

          '& th': {
            backgroundColor: palette.text.primary,
            color: palette.common.white,
            minWidth: 118,
            [breakpoints.down('md')]: {
              minWidth: 'auto',
            },
          },
          '& tr td:first-child': {
            boxShadow: bordered ? '2px 0px 8px 0px rgba(219,219,219,0.75)' : '',
          },
        }} component={Paper}>
          <Table aria-label="customized table">
            {rows?.map((value, key) => (
              <TableBody key={key}>
                {value?.map((column, index) => {
                  const { span, text } = useHeading(tableHeader?.[index] ?? '');

                  return (
                    <TableRow key={index}>
                      {text && (
                        <TableCell component="th" scope="row" rowSpan={span}>
                          <Typography variant="h3" component="h4">
                            {text}
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell>{column}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      )}
      {button && (
        <div css={{
          marginTop: spacing(2.5),

          [breakpoints.down('sm')]: {
            paddingBottom: spacing(2.5),
          },
        }}>
          <PgeButton {...button} />
        </div>
      )}
    </Fragment>)
  );
};

export default ContentList;
